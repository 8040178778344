.daily-checkin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ffffff; /* Dark text for better contrast */
}

.caption {
  font-size: 14px;
  color: #dfd8d8;
  margin-bottom: 16px;
  text-align: center;
}


.time-remaining {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: #ffffff; /* Darker color for text */
}

.time-remaining .icon {
  margin-right: 8px;
  color: #4a90e2;
}

.box-container {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 20px;
}

.box {
  width: 70px;
  height: 70px;
  border: 2px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: border 0.3s;
}

.box img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.box:hover {
  border-color: #4a90e2;
}

.error-message {
  color: #ffffff;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.error-message .icon {
  margin-right: 8px;
}

.claim-button {
  background-color: #4a90e2;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.claim-button:hover {
  background-color: #357ab8;
}

.reset-button {
  background: transparent;
  color: #4a90e2;
  padding: 10px 20px;
  border: 2px solid #4a90e2;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.reset-button:hover {
  background-color: #4a90e2;
  color: white;
}

.image-selection {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subtitle {
  font-size: 24px;
  margin-bottom: 10px;
}

.image-options {
  display: grid;
  grid-template-columns: repeat(4, 72px); /* 4 images per row with 72px width */
  gap: 8px; /* Reduced gap to fit within the box */
  width: 100%; /* Full width for the image options */
}

.image-options button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-options img {
  width: 72px;  /* Set each image width to 72px */
  height: 72px; /* Set each image height to 72px */
  object-fit: cover; /* Ensure the image covers the box without distortion */
  border-radius: 8px; /* Optional: Add some border-radius to make it look better */
}

.image-options img:hover {
  border-color: #4a90e2;
}

.success-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.success-modal h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.success-modal button {
  background-color: #4a90e2;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.success-modal button:hover {
  background-color: #357ab8;
}
