/* Container for the Hold Coin Game */
.hold-coin-game-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    height: 100vh; /* Use full height of the viewport */
    justify-content: flex-start; /* Align items to the top */
}

/* Heading style */
.hold-coin-game-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #ffffff; /* White text for readability */
    border: 2px solid #0c76a0; /* Green border */
    padding: 15px 20px; /* Adjust padding for better spacing */
    border-radius: 8px; /* Rounded corners */
    background-color: rgba(255, 255, 255, 0.1); /* Slightly transparent background */
    text-align: center; /* Center align the title */
    width: 100%; /* Full width for better alignment */
    max-width: 400px; /* Max width to keep it contained */
}

/* Message display */
.hold-coin-game-message {
    font-size: 1rem;
    margin-bottom: 30px; /* Increased gap between message and star area */
    color: #ff5722; /* Orange color for alerts */
    text-align: center; /* Center align the message */
}

/* Star container for holding interaction */
.hold-coin-game-star-area {
    position: relative;
    width: 200px; /* Set a fixed size for the star */
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px dashed #28dfff; /* Dashed border for emphasis */
    border-radius: 50%; /* Make it circular */
    background-color: #00aac0; /* Dark orange background for star area */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
    transition: background-color 0.3s; /* Smooth transition for background */
}

/* Change background on holding */
.hold-coin-game-star-area:active {
    background-color: #e0e0e0; /* Change color when holding */
}

/* Star emoji */
.hold-coin-game-star-emoji {
    font-size: 10rem; /* Increase size for visibility */
}

/* Timer display below the holding area */
.hold-coin-game-timer {
    font-size: 1.5rem;
    color: #ff5722; /* Same orange color as alerts */
    margin-top: 10px; /* Space between holding area and timer */
    text-align: center;
}

/* Next claim timer display */
.hold-coin-game-next-claim-timer {
    font-size: 1.2rem;
    color: #ffffff; /* White color for next claim timer */
    margin-top: 10px; /* Space between holding area timer and next claim timer */
    text-align: center; /* Center align next claim timer */
}

/* Alert box styling */
.hold-coin-game-alert {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Dark overlay */
    display: flex;
    align-items: center;
    justify-content: center;
}

.hold-coin-game-alert-box {
    background-color: #fff; /* White background for alert */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
}

/* Button styling */
.hold-coin-game-alert-button {
    background-color: #4CAF50; /* Green button */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.hold-coin-game-alert-button:hover {
    background-color: #45a049; /* Darker green on hover */
}
