/* Basic resets */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: Arial, sans-serif;
    background-color: #121212;
    color: white;
}

/* Game Container */
.star-catcher-container {
    position: relative;
    background-image: url('/public/gamebg.jpg');
    overflow: hidden; /* Hide overflow */
    display: flex;
    flex-direction: column;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 100vh;
    width: 100%;
}

/* Falling Objects */
.falling-objects-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh; /* Full viewport height */
    overflow: hidden; /* Ensure objects don’t spill out */
}

.falling-object {
    position: absolute;
    font-size: 30px;
    user-select: none;
    cursor: pointer;
    animation: fall 4s linear forwards; /* Ensure they disappear after falling */
}

/* Pseudo-element to increase clickable area */
.falling-object:hover::after,
.falling-object:active::after {
    content: '';
    position: absolute;
    top: -2px;  /* Extend click area upwards by 2px */
    left: -2px; /* Extend click area to the left by 2px */
    right: -2px; /* Extend click area to the right by 2px */
    bottom: -2px; /* Extend click area downwards by 2px */
    z-index: -1; /* Ensure it is behind the object */
    background: rgba(0, 0, 0, 0); /* Make it transparent */
}

.star {
    color: rgb(0, 217, 255);
    font-size: 40px;
}

.bomb {
    color: rgb(255, 255, 255);
    font-size: 40px;
}

/* Game Info */
.game-info {
    display: flex;
    justify-content: space-around; /* Distribute space evenly between items */
    align-items: center; /* Align items vertically */
    width: 100%;
    padding: 20px;
    position: absolute; /* Keeps them positioned relative to the container */
    top: 0px;
}

.score, .dog-points, .time-left {
    border: 2px solid white;
    padding: 10px 15px; /* Add horizontal padding */
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.2); /* Slightly darker background for better contrast */
    font-weight: bold; /* Make points bold */
    margin: 0 10px; /* Consistent margin to separate elements */
    font-size: 18px; /* Larger font size for better readability */
}

/* Optional: For responsiveness */
@media (max-width: 600px) {
    .score, .dog-points, .time-left {
        font-size: 16px;  /* Adjust size for smaller screens */
        padding: 8px;  /* Adjust padding */
        margin: 0 5px; /* Less margin for small screens */
    }
}

.dog-points {
    color: white; /* Change color for dog points */
    font-size: 18px; /* Adjust dog points size */
}

/* Modal for Congratulations */
.fixed {
    position: fixed;
}

.bg-cards {
    background: rgba(50, 50, 50, 0.8);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

/* Buttons */
button {
    cursor: pointer;
}

button.bg-accent {
    background-color: #4CAF50;
    color: white;
}

button.bg-red-500 {
    background-color: #f44336;
    color: white;
}

button.mt-4 {
    margin-top: 16px;
}

button.mt-2 {
    margin-top: 8px;
}

/* Particle Effect */
.particle-container {
    position: absolute;
    pointer-events: none;
}

.particle {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: rgb(0, 217, 255);
    border-radius: 50%;
    opacity: 0.8;
    animation: particle-fade 0.5s forwards;
}

@keyframes particle-fade {
    to {
        opacity: 0;
        transform: translateY(-20px);
    }
}

/* Bomb Blast Effect */
.bomb-blast {
    animation: shake 0.3s;
}

@keyframes shake {
    0% { transform: translate(0); }
    25% { transform: translate(-5px); }
    50% { transform: translate(5px); }
    75% { transform: translate(-5px); }
    100% { transform: translate(0); }
}

/* Falling animation */
@keyframes fall {
    from {
        top: -50px; /* Start just above the viewport */
    }
    to {
        top: 100vh; /* Fall past the bottom of the viewport */
    }
}

.falling-star-image {
    width: 60px; /* Adjust as needed */
    height: 60px; /* Adjust as needed */
}