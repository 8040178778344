/* AllGame.css */
.all-games-container {
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;
  }
  
  .all-games-container h1 {
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .games-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .game-item {
    background-color: #00c6e9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .game-item h2 {
    color: #000000;
    margin-bottom: 10px;
  }
  
  .play-button {
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .play-button:hover {
    background-color: #000000;
  }
  
  .play-button:focus {
    outline: none;
  }
  
  @media (max-width: 768px) {
    .all-games-container {
      width: 100%;
    }
    
    .game-item {
      padding: 10px;
    }
  
    .play-button {
      width: 100%;
    }
  }
  